@import '~@activia/theming';

@include amp-dark();
@include fa-overrides();
@include applyReset();
@include cdk-overlay();
.leader-line {
  z-index: 501;
}
body {
  // needed for connector lines
  overflow-y: hidden;
}
