/* fjalla-one-regular - 400 */
@font-face {
  font-family: 'Fjalla-400';
  font-style: normal;
  font-weight: 400;
  src: local('Fjalla One'), local('FjallaOne-Regular'), url('fjalla-one-v7-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fjalla-one-v7-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* fjalla-one-regular - 500 */
@font-face {
  font-family: 'Fjalla-500';
  font-style: normal;
  font-weight: 500;
  src: local('Fjalla One'), local('FjallaOne-Regular'), url('fjalla-one-v7-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fjalla-one-v7-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* fjalla-one-regular - 600 */
@font-face {
  font-family: 'Fjalla-600';
  font-style: normal;
  font-weight: 600;
  src: local('Fjalla One'), local('FjallaOne-Regular'), url('fjalla-one-v7-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('fjalla-one-v7-latin-regular.woff') format('woff'); /* Modern Browsers */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato-400';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url('lato-v16-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('lato-v16-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato-700';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url('lato-v16-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('lato-v16-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato-900';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url('lato-v16-latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('lato-v16-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
